import React, { useEffect, useRef, useState } from 'react';
import SvgChevronDown from '../assets/svgs/chevron-down-24x24.svg';

interface ProductSubNavProps {
  sectionRefs?: React.Ref<HTMLDivElement>[];
  sectionTitles?: string[];
  isSubMenuOpen?: boolean;
  handleMobileProductButtonClick?: () => void;
  handleMobileProductNavClick?: () => void;
  activeSection?: number;
}

/**
 * This component builds out a copy banner component.
 * @param props.isSubMenuOpen boolean.
 * @param props.handleMobileProductButtonClick function to toggle isSubMenuOpen state in parent.
 * @param props.handleMobileProductNavClick function to update isSubMenuOpen state in parent and close subnav.
 * @param props.activeSection current active section number.
 * @returns ProductSubNavMenu
 */

const ProductSubNav: React.FC<ProductSubNavProps> = (props) => {
  const {
    sectionRefs,
    sectionTitles,
    isSubMenuOpen,
    handleMobileProductButtonClick,
    handleMobileProductNavClick,
    activeSection,
  } = props;

  const [isSticky, setIsSticky] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const scrollNav = ref.current;

    const getDistanceFromTop = (element: any) => {
      if (element.getBoundingClientRect().top <= 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    document.addEventListener(
      'scroll',
      (event) => {
        getDistanceFromTop(scrollNav);
      },
      { passive: true },
    );

    return () => {
      document.removeEventListener('scroll', getDistanceFromTop);
    };
  }, []);

  return (
    <>
      {sectionRefs && (
        <nav
          id="product-scroll-navigation"
          ref={ref}
          className="product-scroll-nav z-50 w-full bg-grey-4 text-ryobi-green uppercase text-xl sticky top-[50px] desktop:top-[60px]"
        >
          <div className="mx-auto desktop:container relative">
            <button
              className="absolute right-xxs desktop:hidden top-[7px] tablet:top-[12px] z-50 text-ryobi-grey-2 p-[4px]"
              onClick={handleMobileProductButtonClick}
            >
              <SvgChevronDown
                width="12px"
                height="12px"
                fill="#ececec"
                className={`z50 ${
                  isSubMenuOpen
                    ? 'transform rotate-180 transition duration-500 ease-in-out'
                    : 'transform transition duration-500 ease-in-out'
                }`}
              />
            </button>
            <ul className="block desktop:flex item-center justify-between flex-nowrap flex-row list-none text-sm tablet:text-lg desktop:text-xl">
              {sectionRefs.map((section, index) => {
                return (
                  <li
                    key={index}
                    className={`relative w-full text-right desktop:text-center ${
                      activeSection === index || isSubMenuOpen
                        ? 'block h-auto opacity-100 transition-all duration-500 ease-in-out'
                        : 'hidden h-0 opacity-0 desktop:opacity-100 desktop:h-auto desktop:block  transition-all duration-500 ease-in-out'
                    }`}
                  >
                    <a
                      href={`#${sectionTitles[index]
                        .replace(' ', '-')
                        ?.toLowerCase()}`}
                      onClick={handleMobileProductNavClick}
                      className={
                        activeSection === index && isSticky
                          ? 'scroll-nav-item w-full font-light inline-block grow py-xxs bg-ryobi-green text-black px-md scroll-nav-item--active'
                          : 'scroll-nav-item w-full font-light grow block desktop:inline-block px-md  py-xxs hover:bg-grey-3 hover:!text-ryobi-green'
                      }
                    >
                      {sectionTitles[index]}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      )}
    </>
  );
};

export default ProductSubNav;
