import React, { useCallback, useRef } from 'react';
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-fullscreen.css';
import './HeroProductGallery.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgFullScreen from 'lightgallery/plugins/fullscreen';

//CMS types etc.
import { IMediaAsset } from '../types/contentfulContentTypes';
import { IHeroProductVideo } from './HeroProduct';
import ContentfulImage from './contentful/ContentfulImage';

export interface HeroProductGalleryProps {
  images?: IMediaAsset[];
  videos?: IHeroProductVideo[];
}

export interface HeroProductGalleryImage {
  src?: string;
  thumb?: string;
}

export interface HeroProductGalleryVideo {
  src?: string;
  poster?: string;
}

const HeroProductGallery: React.FC<HeroProductGalleryProps> = (props) => {
  const lightGallery = useRef<any>(null);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const onAfterOpen = () => {
    //console.log(lightGallery.current);
    //TODO: add overlay to video thumbnails
  };

  const { images, videos } = props;

  const imageAssets: HeroProductGalleryImage[] = images?.map((image) => {
    return {
      src: image?.file?.url,
      thumb: image?.file?.url,
    };
  });

  const videoAssets: HeroProductGalleryVideo[] = videos?.map((item) => {
    return {
      src: item?.externalUrl,
      poster: item?.poster,
    };
  });

  const galleryAssets = [...imageAssets, ...videoAssets];

  const noOfExtraImages =
    images.length + videos.length > 3 ? images.length + videos.length - 3 : 0;
  const countOverlay = (
    <>
      <div className="overLay absolute top-0 left-0 w-full h-full bg-grey-3 opacity-80 text-ryobi-green"></div>
      <div className="overlay-inner w-full text-ryobi-green absolute top-[50%] -mt-[25%] font-medium text-center text-2xl">
        +{noOfExtraImages}
      </div>
    </>
  );

  return (
    <div className="gallery-wrapper">
      <LightGallery
        licenseKey={process.env.GATSBY_LIGHTGALLERY_LICENSE_KEY}
        onInit={onInit}
        onAfterOpen={onAfterOpen}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgVideo, lgFullScreen]}
        showZoomInOutIcons={true}
        animateThumb={false}
        toggleThumb={false}
        thumbWidth={60}
        thumbHeight={'60px'}
        thumbMargin={12}
        alignThumbnails={'left'}
        actualSize={false}
        autoplayControls={false}
        download={false}
        rotate={false}
        getCaptionFromTitleOrAlt={false}
        addClass={'heroProductGallery'}
        loadYouTubePoster={false}
        youTubeThumbSize={1}
        mobileSettings={{
          showCloseIcon: true,
        }}
        youTubePlayerParams={{
          modestbranding: 1,
          showinfo: 0,
          controls: 1,
        }}
      >
        {galleryAssets.map((item, index) => {
          let galleryThumbs = <></>;
          if (item?.src?.includes('youtube')) {
            galleryThumbs = (
              <a
                key={`gallery-asset-${index}`}
                data-lg-size="854x480"
                data-src={`${item?.src}&mute=false`}
                data-poster={`${item?.poster}?fm=webp`}
                className={`${
                  index > 2
                    ? 'hidden'
                    : 'relative inline-block outline outline-1 outline-ryobi-green hover:outline-accent-blue w-[60px] h-[60px] mr-xs rounded-md overflow-hidden'
                }`}
              >
                {item?.poster && (
                  <ContentfulImage
                    image={item?.poster}
                    alt=""
                    loading="eager"
                    className="w-[60px] h-[60px]"
                  />
                )}

                {index === 2 && index !== galleryAssets.length - 1 ? (
                  countOverlay
                ) : (
                  <></>
                )}
              </a>
            );
          } else {
            galleryThumbs = (
              <>
                {item?.src && (
                  <a
                    key={`gallery-asset-${index}`}
                    href={item?.src}
                    className={`${
                      index > 2
                        ? 'hidden'
                        : 'relative inline-block outline outline-1 outline-ryobi-green hover:outline-accent-blue w-[60px] h-[60px] mr-xs rounded-md overflow-hidden'
                    }`}
                  >
                    <div className="flex justify-center">
                      {item?.src && (
                        <ContentfulImage
                          image={item?.src}
                          alt=""
                          loading="eager"
                          className="h-[60px]"
                        />
                      )}
                    </div>

                    {index === 2 && index !== galleryAssets.length - 1 ? (
                      countOverlay
                    ) : (
                      <></>
                    )}
                  </a>
                )}
              </>
            );
          }
          return <React.Fragment key={index}>{galleryThumbs}</React.Fragment>;
        })}
      </LightGallery>
    </div>
  );
};

export default HeroProductGallery;
