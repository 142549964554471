import React from 'react';
import Carousel from './ui/Carousel';
import { IMediaAsset, TTheme } from '../types/contentfulContentTypes';
import ProductIncludesCard from './ProductIncludesCard';

export interface IProductIncludesCarouselSlideContent {
  title?: string;
  featuredImage?: IMediaAsset | string | null;
  featuredImageDescription?: string;
}

interface ProductIncludesCarouselProps {
  items?: IProductIncludesCarouselSlideContent[];
  title?: string | null;
  theme?: TTheme | string | null;
}

/**
 * This component builds out a product includes carousel
 * @param props The carousel title, items, and theme.
 * @returns ProductCarousel
 */

const ProductIncludesCarousel: React.FC<ProductIncludesCarouselProps> = (
  props,
) => {
  const { items, title, theme } = props;

  const carouselArgs = {
    breakpoints: {
      650: {
        slidesPerView: 2,
      },
      850: {
        slidesPerView: 3,
      },
      950: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
    className: 'container',
    keyboard: {
      enabled: true,
    },
    watchOverflow: true,
    loop: false,
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 10,
  };

  return (
    <div className="my-sm">
      {title && (
        <div className="container mx-auto my-sm border-0 border-b-2 border-solid border-ryobi-green text-center">
          <span className="bg-white text-grey-3 translate-y-2 inline-block px-xs type-h4 tablet:type-h3 font-book uppercase">
            {title}
          </span>
        </div>
      )}

      <Carousel
        carouselArgs={carouselArgs}
        customNavigation={true}
        className="container"
        theme={theme}
      >
        {items?.map((item, index) => {
          return (
            <React.Fragment key={`product-includes-card-${index}`}>
              {item.featuredImage && (
                <ProductIncludesCard
                  title={item.title}
                  featuredImage={item.featuredImage}
                  featuredImageDescription={item.featuredImageDescription}
                />
              )}
            </React.Fragment>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductIncludesCarousel;
