import React from 'react';
import ContentfulImage from './contentful/ContentfulImage';
import { ICarouselSlideContent } from './ui/Carousel';
import { IMediaAsset, TTheme } from '../types/contentfulContentTypes';

interface FeaturePromotionSingleProps {
  item?: ICarouselSlideContent;
  title?: string | null;
  theme?: TTheme;
}

/**
 * This component builds out a promotion and feature single feature content item
 * @param props The single feature promotion content item, title and theme.
 * @returns FeaturePromotionSingle
 */

const FeaturePromotionSingle: React.FC<FeaturePromotionSingleProps> = (
  props,
) => {
  const { item, title, theme } = props;

  const isImageAvailable = item.image[0];

  return (
    <div
      className={`${
        theme === 'Dark'
          ? 'bg-grey-3 text-white text-center tablet:text-left'
          : 'bg-white text-grey-3 text-left'
      }`}
    >
      {title && (
        <div className="container mx-auto border-0 border-b-2 border-solid border-ryobi-green m-sm text-center">
          <span className="bg-white text-grey-3 translate-y-2 inline-block px-xs type-h3 font-book uppercase">
            {title}
          </span>
        </div>
      )}
      <div
        className={`features-container container h-full mb-lg items-center flex  flex-col-reverse desktop:flex-row ${
          !isImageAvailable && 'bg-ryobi-green'
        }`}
      >
        <div
          className={`features-text bg-ryobi-green px-sm ${
            isImageAvailable ? 'desktop:h-full' : ''
          } tablet:my-md desktop:flex-1 desktop:flex desktop:items-center`}
        >
          <div
            className={`container mx-auto py-sm ${
              isImageAvailable ? 'w-full desktop:my-xxl' : 'desktop:w-1/2'
            } text-black`}
          >
            {item.primaryText && (
              <h4 className="font-heavy uppercase text-right mb-sm">
                {item.primaryText}
              </h4>
            )}
            {item.secondaryText && (
              <h5 className="type-h5">{item.secondaryText}</h5>
            )}
            {item.bodyText && (
              <div className="type-b2 text-18 pb-sm font-medium tablet:text-20">
                {item.bodyText}
              </div>
            )}
          </div>
        </div>
        {item.image[0] && (
          <div className="features-image desktop:flex-1 justify-center object-cover">
            <ContentfulImage
              image={item.image?.[0]}
              className={`flex justify-center object-cover object-center w-auto h-auto  rounded-xl tablet:mx-lg desktop:scale-125 desktop:m-xxl`}
              alt={
                isImageAvailable
                  ? (item.image[0] as IMediaAsset).description
                  : ''
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturePromotionSingle;
