import React, { useContext } from 'react';
import {
  IMediaAsset,
  ITopicProductFamily,
} from '../types/contentfulContentTypes';
import ContentfulImage from './contentful/ContentfulImage';
import * as styles from '../components/HeroProduct.module.css';
import LogoHomeDepot from '../assets/svgs/home-depot-logo-60x60.svg';
import IconFacebook from '../assets/svgs/facebook-32x32.svg';
import IconInstagram from '../assets/svgs/instagram-logo-green.svg';
import { GlobalStateContext } from '../hooks/globalStateContext';
import HeroProductGallery from './HeroProductGallery';
import ContentfulLink from './contentful/ContentfulLink';

export interface HeroProductProps {
  name?: string;
  model?: string;
  featureImage?: IMediaAsset;
  highlights?: React.ReactNode;
  sku?: string;
  thumbnailImages?: IMediaAsset[];
  videos?: IHeroProductVideo[];
  isComingSoon?: boolean;
  isDiscontinued?: boolean;
  productFamilies?: ITopicProductFamily[];
}

export interface IHeroProductVideo {
  externalUrl?: string;
  poster?: string;
}

/**
 * This component builds out a Hero Product component.
 * @param props hero product props.
 * @returns Hero Product component
 */

const HeroProduct: React.FC<HeroProductProps> = (props) => {
  const {
    name,
    model,
    featureImage,
    highlights,
    sku,
    thumbnailImages,
    videos,
    isComingSoon,
    isDiscontinued,
    productFamilies,
  } = props;

  const { microcopy } = useContext(GlobalStateContext);
  const homeDepotLink = sku ? `${microcopy.HomeDepotProductLink}${sku}` : null;

  let productFamilyLineUpImage: ITopicProductFamily;
  productFamilies?.map((family) => {
    if (family.title === '40V HP' || family.title === 'ONE+ HP') {
      productFamilyLineUpImage = family;
    }
  });

  const renderProductLink = (
    isComingSoon: boolean,
    sku: string,
    storeLocatorLink: string,
  ) => {
    return (
      <>
        {isComingSoon && (
          <span className="cursor-auto bg-ryobi-green text-grey-4 uppercase btn-secondary relative hover:!text-black  hover:!bg-ryobi-green">
            <span className="ml-0">
              {microcopy.ComingSoonText
                ? microcopy.ComingSoonText
                : 'Coming soon'}
            </span>
          </span>
        )}

        {!isComingSoon && (
          <a
            href={homeDepotLink ?? storeLocatorLink}
            className="bg-ryobi-green text-grey-4 uppercase px-0 btn-secondary relative hover:!text-ryobi-green hover:!outline hover:!outline-1 hover:!outline-ryobi-green hover:drop-shadow-ryobi-green"
          >
            {homeDepotLink && (
              <LogoHomeDepot
                width="24"
                height="24"
                alt="The Home Depot Logo"
                className="absolute top-[50%] -translate-y-[50%] left-1"
              />
            )}

            <span className={isDiscontinued ? 'ml-0' : 'ml-sm'}>
              {sku
                ? microcopy?.ProductBuyNowButtonText
                : microcopy?.StoreLocatorText}
            </span>
          </a>
        )}
      </>
    );
  };

  return (
    <section className="hero-product block tablet:flex my-sm">
      <div className="featured-image flex justify-center mb-sm tablet:mb-0 tablet:w-1/2 desktop:w-3/5 h-[40vh] desktop:h-[80vh]">
        {featureImage && (
          <ContentfulImage
            image={featureImage}
            alt={featureImage.description || name}
            loading="eager"
            className="w-auto"
            objectFit={'contain'}
          />
        )}
      </div>
      <div className="main-content w-full tablet:w-1/2 desktop:w-2/5">
        <div
          className={`bg-black text-white rounded-[24px] p-sm mb-xs ${styles.productHighlights}`}
        >
          <div className="main-content--text ">
            {name && <h1 className="text-2xl font-normal uppercase">{name}</h1>}
            {model && (
              <div className="model uppercase text-xs font-normal mt-xxs">
                {microcopy?.ProductModelLabel ?? 'Model: #'}
                {model}
              </div>
            )}
            {highlights && (
              <div className="highlights text-base pl-xs mt-xs mb-md font-light leading-relaxed">
                <div className={styles.highlightsListWrap}>{highlights}</div>
              </div>
            )}
            <div className="flex mt-xs">
              <div className="link-out w-1/2 flex items-center">
                {renderProductLink(
                  isComingSoon,
                  sku,
                  microcopy?.StoreLocatorLink,
                )}
              </div>
              <div className="social-media-links w-1/2 text-right">
                <ul className="social-media-link-wrapper list-none m-0 p-0 flex justify-end items-center mt-xxs">
                  <li>
                    <a
                      href={microcopy?.ProductDetailFacebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconFacebook
                        width="24"
                        height="24"
                        alt="Ryobi Canada Facebook Page"
                        className="fill-ryobi-green hover:fill-grey-2"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={microcopy?.ProductDetailInstagramLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconInstagram
                        width="24"
                        height="24"
                        alt="Ryobi Canada Instagram Page"
                        className="fill-ryobi-green hover:fill-grey-2"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {thumbnailImages && (
          <div className="main-content--thumbnails flex flex-col items-center tablet:flex-row">
            <HeroProductGallery images={thumbnailImages} videos={videos} />
            {productFamilyLineUpImage?.heroProductImage?.primaryImage && (
              <ContentfulLink
                link={productFamilyLineUpImage?.heroProductImage?.link?.[0]}
                className="mt-xs"
              >
                <ContentfulImage
                  image={
                    productFamilyLineUpImage?.heroProductImage
                      ?.primaryImage?.[0]
                  }
                  className="w-[140px] [&>picture>img]:!object-contain"
                />
              </ContentfulLink>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroProduct;
