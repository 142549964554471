import React from 'react';
import ProductSectionTitle from './ProductSectionTitle';

export interface IProductTableSpecValues {
  key?: string;
  value?: string;
}

export interface IProductTableSpec {
  title?: string;
  value?: IProductTableSpecValues[];
}

export interface ProductSpecTableProps {
  className?: string;
  title?: string;
  specifications?: IProductTableSpec[];
  bodyText?: React.ReactNode;
}

/**
 * This component builds out a copy banner component.
 * @param props.className classes to style section.
 * @param props.specifications product specifications title and key/value pairs
 * @returns ProductSpecTable
 */

const ProductSpecTable: React.FC<ProductSpecTableProps> = (props) => {
  const { className, title, specifications } = props;

  return (
    <>
      {specifications && (
        <section className={`product-specs container mb-sm ${className ?? ''}`}>
          <ProductSectionTitle title={title} />

          {specifications.map((specification, index) => {
            const isOddNoOfSpecs = specification?.value
              ? specification?.value.length % 2
              : 0;
            let emptySpec = <></>;
            if (isOddNoOfSpecs) {
              emptySpec = (
                <div
                  className="hidden desktop:block min-w-full min-h-full border-b border-b-ryobi-green"
                  key={index}
                >
                  &nbsp;
                </div>
              );
            }
            return (
              <div
                className="block"
                key={`${specification?.title}-outer-${index}`}
              >
                {specification?.value && specification?.title && (
                  <h4 className="px-sm py-xxs text-xl font-bold">
                    {specification?.title}
                  </h4>
                )}

                {specification?.value && (
                  <div className="block desktop:grid overflow-hidden grid-cols-2 auto-rows-auto gap-0 border-t border-t-ryobi-green">
                    {specification.value.map((value, indexInner) => {
                      return (
                        <React.Fragment key={indexInner}>
                          <div className="min-w-full min-h-full flex text-grey-4">
                            <div className="bg-ryobi-green  text-lg w-1/3 border-b border-b-white px-[12px] py-[6px] item-center justify-center">
                              {value?.key}
                            </div>
                            <div className="text-grey-4 flex-grow w-2/3 text-lg border-b border-b-ryobi-green px-[12px] py-[6px] item-center justify-center">
                              {value?.value}
                            </div>
                          </div>
                          {specification?.value.length - 1 === indexInner ? (
                            emptySpec
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </section>
      )}
    </>
  );
};

export default ProductSpecTable;
