import React, { useContext } from 'react';
import { ICardGeneric } from '../components/CardGeneric';
import Epigraph from '../components/Epigraph';
import FeaturePromotionCarousel from '../components/FeaturePromotionCarousel';
import FeaturePromotionSingle from '../components/FeaturePromotionSingle';
import { ImageCopyCenterProps } from '../components/ImageCopyCenter';
import ProductCarousel from '../components/ProductCarousel';
import ProductFlexibleBodyText from '../components/ProductFlexibleBodyText';
import ProductIncludesCarousel, {
  IProductIncludesCarouselSlideContent,
} from '../components/ProductIncludesCarousel';
import { IProductManual } from '../components/ProductManuals';
import ProductSectionTitle from '../components/ProductSectionTitle';
import ProductSpecTable from '../components/ProductSpecTable';
import { IProductWarrantyItems } from '../components/ProductWarrantyDetails';
import {
  ICarouselSlideContent,
  IProductCarouselSlideContent,
} from '../components/ui/Carousel';
import Tabs, { ITabItems } from '../components/ui/Tabs';
import { GlobalStateContext } from '../hooks/globalStateContext';
import { IMediaAsset, TSizing, TTheme } from '../types/contentfulContentTypes';

export interface SectionsProps {
  featuresPromotionItems?: ICarouselSlideContent[];
  cardFeaturesItems?: ICardGeneric[];
  productFamilyProps?: ImageCopyCenterProps;
  productFamilyAppearancePadding?: TSizing;
  productFamilyAppearanceMargin?: TSizing;
  productFamilyAppearanceTheme?: TTheme;
  productFamilyAppearanceBgImage?: IMediaAsset;
  specifications?: any;
  productSpecificationsBodyText?: React.ReactNode;
  productIncludesBodyText?: React.ReactNode;
  productIncludesItems?: IProductIncludesCarouselSlideContent[];
  kitSpecifications?: any;
  productLineProps?: ImageCopyCenterProps;
  productLineAppearancePadding?: TSizing;
  productLineAppearanceMargin?: TSizing;
  productLineAppearanceTheme?: TTheme;
  productLineAppearanceBgImage?: IMediaAsset;
  tabItems?: ITabItems[];
  productManuals?: IProductManual[];
  tabModel?: string;
  warrantyItems?: IProductWarrantyItems[];
  productRelatedItems?: IProductCarouselSlideContent[];
  hasEpigraphAr?: boolean;
}

//Function to output components based on section name
export const getSectionComponentByName = (
  sectionName: string,
  props: SectionsProps,
): JSX.Element => {
  const { microcopy } = useContext(GlobalStateContext);

  const {
    featuresPromotionItems,
    specifications,
    productSpecificationsBodyText,
    productIncludesBodyText,
    productIncludesItems,
    kitSpecifications,
    tabItems,
    productManuals,
    tabModel,
    warrantyItems,
    productRelatedItems,
    hasEpigraphAr,
  } = props;

  switch (sectionName) {
    case 'Features':
      return (
        <>
          {featuresPromotionItems && (
            <section>
              <div className="container">
                {hasEpigraphAr && <Epigraph model={tabModel} />}
                {featuresPromotionItems.length === 1 && (
                  <FeaturePromotionSingle
                    title={microcopy?.ProductSectionFeaturesTitle}
                    item={featuresPromotionItems?.[0]}
                    theme={'Light'}
                  />
                )}
              </div>
              {featuresPromotionItems.length > 1 && (
                <FeaturePromotionCarousel
                  title={microcopy?.ProductSectionFeaturesTitle}
                  items={featuresPromotionItems}
                  theme={'Light'}
                />
              )}
            </section>
          )}
        </>
      );
    case 'Specifications' || 'Specs':
      return (
        <>
          <section>
            <div className="container">
              <ProductSectionTitle
                title={
                  microcopy?.ProductSectionSpecificationsTitle ||
                  'Specifications'
                }
              />
            </div>

            {productSpecificationsBodyText ? (
              <div className="text-grey-3 font-light">
                <ProductFlexibleBodyText
                  description={productSpecificationsBodyText}
                />
              </div>
            ) : (
              <ProductSpecTable
                className="mb-md"
                title={null}
                specifications={specifications}
              />
            )}
          </section>
        </>
      );
    case 'Includes':
      return (
        <>
          <div className="container">
            <ProductSectionTitle
              title={microcopy?.ProductSectionsIncludesTitle || 'Includes'}
            />
          </div>

          {productIncludesBodyText ? (
            <div className="text-grey-3 font-light">
              <ProductFlexibleBodyText description={productIncludesBodyText} />
            </div>
          ) : (
            <>
              {productIncludesItems && (
                <ProductIncludesCarousel items={productIncludesItems} />
              )}
            </>
          )}

          {kitSpecifications && (
            <ProductSpecTable
              className="mb-md"
              title={null}
              specifications={kitSpecifications}
            />
          )}
        </>
      );
    case 'Support':
      return (
        <>
          {tabItems && (
            <section className="container">
              <ProductSectionTitle
                title={microcopy?.ProductSectionsSupportTitle || 'Support'}
              />
              <Tabs
                model={tabModel}
                items={tabItems}
                warrantyItems={warrantyItems}
                manuals={productManuals}
              />
            </section>
          )}
        </>
      );
    case 'Related Products':
      return (
        <>
          {productRelatedItems && (
            <>
              <div className="container">
                <ProductSectionTitle
                  title={
                    microcopy?.ProductSectionRelatedProductsTitle ||
                    'Related Products'
                  }
                />
              </div>

              <div className="mt-xl mb-lg">
                <ProductCarousel items={productRelatedItems} />
              </div>
            </>
          )}
        </>
      );
    default:
      return null;
  }
};
