import React, { useContext, useEffect, useState, useRef } from 'react';
import { IContentLink, IMediaAsset } from '../../types/contentfulContentTypes';
import ContentfulLink from '../contentful/ContentfulLink';
import ProductManuals, { IProductManual } from '../ProductManuals';
import ProductWarrantyDetails, {
  IProductWarrantyItems,
} from '../ProductWarrantyDetails';
import * as alert from '../../services/alert.service';
import { GlobalStateContext } from '../../hooks/globalStateContext';
export interface ITabItems {
  name?: string;
  bodyText: React.ReactNode | null;
  primaryImage?: IMediaAsset | string | null;
  links?: IContentLink[];
}

export interface TabsProps {
  model?: string;
  items?: ITabItems[];
  warrantyItems?: IProductWarrantyItems[];
  manuals?: IProductManual[];
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { model, items, warrantyItems, manuals } = props;
  const defaultTab: string = items?.[0]?.name;
  const itemsRef = useRef(items);
  const [openTab, setOpenTab] = useState(defaultTab);
  const { microcopy } = useContext(GlobalStateContext);

  const handleTabClick = (tabName: string) => {
    setOpenTab(tabName);
  };

  const handleLeavingCadSite = (
    e: React.MouseEvent<HTMLElement>,
    link: string,
  ) => {
    e.preventDefault();
    alert.show(
      'info',
      <h4>{microcopy?.LeavingCADSiteTitle ?? 'Note'}</h4>,
      <p className="mt-0">{microcopy?.LeavingCADSite}</p>,
      link,
    );
  };

  useEffect(() => {
    if (items?.length !== itemsRef?.current?.length) {
      itemsRef.current = items;
      setOpenTab(defaultTab);
    }
  }, [items]);

  const getTabContentByName = (
    tabName: string,
    tabBodyText: React.ReactNode | null,
    tabPrimaryImage: IMediaAsset | string | null,
    tabLinks: IContentLink[] | null,
  ) => {
    const partsContent = (
      <div className="container px-0 w-full tablet:w-[70%] font-light">
        {tabBodyText && (
          <div className="mb-md text-grey-3 text-center tablet:text-left">
            {tabBodyText}
          </div>
        )}
        {tabLinks && (
          <div className="block text-center tablet:text-left">
            <ContentfulLink
              link={tabLinks?.[0]}
              className="btn-primary font-medium"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                handleLeavingCadSite(e, tabLinks?.[0].externalUrl)
              }
            >
              {tabLinks?.[0].text}
            </ContentfulLink>
          </div>
        )}
      </div>
    );

    const helpPlusContent = (
      <div className="container w-full px-0 tablet:w-[82%] font-light flex flex-wrap items-center">
        <div className="w-full tablet:w-2/5 pr-sm align-middle">
          {tabPrimaryImage && (
            <img
              src={tabPrimaryImage?.file?.url}
              alt={tabPrimaryImage?.description}
              className="w-full h-auto mb-xxs tablet:mb-0 max-h-[85px] tablet:max-h-[120px] desktop:ml-md"
            />
          )}
        </div>
        <div className="w-full tablet:w-3/5 tablet:pl-sm tablet:border-l-2 tablet:border-l-grey-2">
          {tabBodyText && (
            <div className="parts-bodytext mb-md text-grey-3 text-center tablet:text-left">
              {tabBodyText}
            </div>
          )}
          {tabLinks && (
            <div className="block pb-xs text-center tablet:text-left">
              <ContentfulLink
                link={tabLinks?.[0]}
                className="btn-primary font-medium"
              >
                {tabLinks?.[0].text}
              </ContentfulLink>
            </div>
          )}
        </div>
      </div>
    );

    switch (tabName) {
      case 'Manuals':
        return <ProductManuals model={model} manuals={manuals} />;
      case 'Registration & Warranty':
        return <ProductWarrantyDetails items={warrantyItems} />;
      case 'PARTS':
        return partsContent;
      case 'HELP+':
        return helpPlusContent;
      default:
        return `No Tab content found for ${tabName}`;
    }
  };

  return (
    <>
      <div className="">
        <div className="flex flex-col items-center justify-center">
          <ul className="flex flex-wrap space-x-0 list-none">
            {items.map((tab) => (
              <li
                key={tab.name}
                className="after:content-['|'] after:text-grey-3 after:font-normal after:text-xs after:tablet:text-lg after:pl-0 after:-translate-y-0.5 after:self-center after:inline-flex last:after:content-['']"
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick(tab.name);
                  }}
                  className={`${
                    openTab === tab.name
                      ? `text-grey-3 hover:text-grey-3 cursor-default`
                      : `text-accent-blue hover:bg-accent-blue hover:text-white transition-colors`
                  } inline-block px-2 py-1 uppercase font-normal text-xs tablet:text-xl`}
                >
                  {tab.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="p-1 tablet:p-3 mt-6 w-full">
            {items.map((tab) => (
              <div
                key={tab.name}
                className={tab.name === openTab ? 'block' : 'hidden'}
              >
                {getTabContentByName(
                  tab?.name,
                  tab?.bodyText,
                  tab?.primaryImage,
                  tab?.links,
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
