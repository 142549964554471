import React from 'react';

interface ProductSectionTitleProps {
  title?: string;
}

/**
 * This component builds out a copy banner component.
 * @param props A string of product section title.
 * @returns ProductSectionTitle
 */

const ProductSectionTitle: React.FC<ProductSectionTitleProps> = (props) => {
  const { title } = props;

  return (
    <>
      {title && (
        <div className="container mx-auto border-0 border-b-2 border-solid border-ryobi-green m-sm text-center">
          <span className="bg-white text-grey-3 translate-y-2 inline-block px-xs type-h3 font-book uppercase">
            {title}
          </span>
        </div>
      )}
    </>
  );
};

export default ProductSectionTitle;
