import React from 'react';

interface ProductFlexibleBodyTextBodyTextProps {
  className?: string;
  description?: React.ReactNode;
}

/**
 * This component builds out a product includes body text component.
 * @param props A string of the product description or generic text.
 * @returns ProductFlexibleBodyText
 */

const ProductFlexibleBodyText: React.FC<
  ProductFlexibleBodyTextBodyTextProps
> = (props) => {
  const { className, description } = props;

  return (
    <section className={`${className ?? ''}`}>
      {description && (
        <div className="container px-xl py-md">{description}</div>
      )}
    </section>
  );
};

export default ProductFlexibleBodyText;
