import React from 'react';
import '../components/Epigraph.module.css';
import { Helmet } from 'react-helmet';

interface EpigraphProps {
  model?: string;
}

interface IEpigraphARProps {
  sku?: string;
  theme?: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'epigraph-ar': IEpigraphARProps;
    }
  }
}

/**
 * @param props product model
 * @returns EpigraphAR
 */

export default function Epigraph(props: EpigraphProps) {
  const { model } = props;

  return (
    <>
      <Helmet>
        <script
          async
          type="module"
          src="//www.unpkg.com/@epigraph/ar@2.0.0-alpha.7/dist/epigraph-ar.min.js"
        ></script>
      </Helmet>
      <div className="product-tour block w-full h-[400px] bg-white">
        <epigraph-ar
          auto-start
          viewer-visible
          product-tour-visible
          sku={model}
          theme="ryobi"
          ua-code=""
          button-mode="mobile desktop"
          ar-modes="scene-viewer quick-look"
          camera-orbit="55deg 69deg auto"
          interaction-prompt-threshold="3000"
          shadow-intensity="1"
          poster-foreground="//ryobi-demo.s3-website.us-east-2.amazonaws.com/21/images/Ryobi-Logo.png"
        ></epigraph-ar>
      </div>
    </>
  );
}
