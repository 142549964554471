import React, { useState, useRef, useEffect, useContext } from 'react';
import { IMediaAsset } from '../types/contentfulContentTypes';
import ContentfulImage from './contentful/ContentfulImage';

export interface ProductIncludesCardProps {
  title?: string;
  featuredImage?: IMediaAsset | string;
  featuredImageDescription?: string;
}
/**
 * This component builds out a Product Includes Card component
 * @param props name, featured image
 * @returns ProductIncludesCard
 */

const ProductIncludesCard: React.FC<ProductIncludesCardProps> = (props) => {
  const { featuredImage, featuredImageDescription, title } = props;
  return (
    <div className="container mx-auto max-w-xs px-1 relative">
      <div className={`p-xs`}>
        <ContentfulImage
          image={
            (featuredImage as IMediaAsset)?.file?.url
              ? `${(featuredImage as IMediaAsset)?.file?.url}?fm=webp&w=600`
              : featuredImage
          }
          alt={featuredImageDescription || title}
          className={`aspect-square duration-300`}
        />
      </div>
      <h3
        className={`text-grey-3 uppercase my-xs type-b2 text-base text-center font-light`}
      >
        {title}
      </h3>
    </div>
  );
};

export default ProductIncludesCard;
