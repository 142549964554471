import React, { useContext } from 'react';
import { GlobalStateContext } from '../hooks/globalStateContext';
import { IContentLink, IMediaAsset } from '../types/contentfulContentTypes';
import ContentfulImage from './contentful/ContentfulImage';
import ContentfulLink from './contentful/ContentfulLink';
import * as alert from '../services/alert.service';

export interface IProductWarrantyItems {
  description?: string | null;
  logo?: IMediaAsset | string | null;
  logoText?: string | null;
  title?: string | null;
  link?: IContentLink;
}

interface ProductWarrantyProps {
  items?: IProductWarrantyItems[];
}

/**
 * This component builds out a product warranty component.
 * @param props A string of the product description or generic text.
 * @returns ProductWarrantyDetails
 */

const ProductWarrantyDetails: React.FC<ProductWarrantyProps> = (props) => {
  const { items } = props;
  const { microcopy } = useContext(GlobalStateContext);

  const handleLeavingCadSite = (
    e: React.MouseEvent<HTMLElement>,
    link: string,
  ) => {
    e.preventDefault();
    alert.show(
      'info',
      <h4>Note</h4>,
      <p className="mt-0">{microcopy?.LeavingCADSite}</p>,
      link,
    );
  };

  return (
    <div className="container w-full desktop:w-[70%]">
      {items &&
        items.map((item, index) => {
          return (
            <div
              className="block tablet:flex items-start w-full mb-xxs last:mb-0 last:pb-0 only:pb-0 only:mb-0"
              key={`warranty-item-${index}`}
            >
              {item.logo && (
                <div className="w-full tablet:w-2/5 text-center">
                  <ContentfulLink link={item.link}>
                    <ContentfulImage
                      image={item.logo}
                      className="max-h-[150px] aspect-square object-contain mb-sm"
                      alt={item.logoText ?? item.title}
                      objectfit={'contain'}
                    />
                    <div className="text-ryobi-green font-light">
                      {item.logoText}
                    </div>
                  </ContentfulLink>
                </div>
              )}

              <div
                className={`w-full ${
                  item.title?.toLowerCase() === 'no warranty'
                    ? `tablet:w-full tablet:mb-sm`
                    : `tablet:w-3/5`
                } text-center tablet:text-left`}
              >
                {index === 0 && item.title && (
                  <h3 className="text-4xl text-grey-3 font-bold">
                    {item.title}
                  </h3>
                )}
                {item.description && (
                  <p className="font-light">{item.description}</p>
                )}

                {index === items.length - 1 && (
                  <div className="warranty-buttons flex flex-col tablet:flex-row w-full mt-sm text-center tablet:text-left">
                    <ContentfulLink
                      link={item.link}
                      className="btn-primary font-medium my-xxs tablet:my-0 tablet:mr-xs uppercase"
                    >
                      {microcopy?.WarrantyLearnMoreText}
                    </ContentfulLink>

                    {items.find((item) => item.title !== 'No Warranty') && (
                      <ContentfulLink
                        link={microcopy?.RegisterProductButtonLink}
                        className="btn-primary font-medium tablet:mr-xs uppercase"
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          handleLeavingCadSite(
                            e,
                            microcopy?.RegisterProductButtonLink,
                          )
                        }
                      >
                        {microcopy?.RegisterProductButtonText}
                      </ContentfulLink>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ProductWarrantyDetails;
