import React from 'react';
import ContentfulImage from './contentful/ContentfulImage';
import ContentfulLink from './contentful/ContentfulLink';

interface ProductManualsProps {
  className?: string;
  model?: string;
  manuals?: IProductManual[];
}

export interface IProductManual {
  document_title?: string;
  file?: string;
  id?: number;
  published?: string;
  size?: string;
  thumb?: string;
  title?: string;
}

/**
 * This component builds out a product manual component.
 * @param props A string of the product model, manual api url and optional class name.
 * @returns ProductManuals
 */

const ProductManuals: React.FC<ProductManualsProps> = (props) => {
  const { className, model, manuals } = props;

  return (
    <>
      {manuals?.length > 0 && (
        <div className="flex flex-wrap container w-full desktop:max-w-[85%] px-0">
          {manuals?.map((manual) => {
            return (
              <React.Fragment key={manual.id}>
                <div className="w-full tablet:w-1/2 flex mb-sm group">
                  <div className="ml-0 tablet:ml-sm">
                    <ContentfulLink
                      link={manual?.file}
                      className="hover:border-ryobi-green"
                    >
                      <ContentfulImage
                        image={manual?.thumb}
                        className="border hover:border-ryobi-green group-hover:border-ryobi-green transition-all max-w-[200px] max-h-[200px]"
                        alt={manual?.title}
                      />
                    </ContentfulLink>
                  </div>
                  <div className="text-grey-3 font-light ml-sm">
                    <ContentfulLink
                      link={manual?.file}
                      className="hover:text-grey-4 group-hover:text-black"
                    >
                      <div className="break-all">{manual?.title}</div>
                      <div>Published: {manual?.published}</div>
                      <div>Filesize: {manual?.size}</div>
                    </ContentfulLink>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {manuals?.length === 0 && <p>No manuals found for model: {model}</p>}
    </>
  );
};

export default ProductManuals;
