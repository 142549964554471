import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContext } from '../types/pageContext';
import HeroProduct, { IHeroProductVideo } from '../components/HeroProduct';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import useScrollSpy from 'react-use-scrollspy';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalStateContext } from '../hooks/globalStateContext';
import useMediaQuery from '../hooks/useMediaQuery';
import ProductSubNav from '../components/ProductSubNav';
import ImageCopyCenter, {
  ImageCopyCenterProps,
} from '../components/ImageCopyCenter';
import CopyBanner from '../components/CopyBanner';
import {
  IContentLink,
  IMediaAsset,
  TSizing,
  TTheme,
} from '../types/contentfulContentTypes';
import { IProductIncludesCarouselSlideContent } from '../components/ProductIncludesCarousel';
import {
  ICarouselSlideContent,
  IProductCarouselSlideContent,
} from '../components/ui/Carousel';
import { ITabItems } from '../components/ui/Tabs';
import { IProductWarrantyItems } from '../components/ProductWarrantyDetails';
import { ICardGeneric } from '../components/CardGeneric';
import {
  getSectionComponentByName,
  SectionsProps,
} from '../utils/getProductSectionComponentByName';
import { getProductManuals } from '../services/productManuals.service';
import CardsFeature from '../components/CardsFeature';
import Appearance from '../components/contentful/ContentfulAppearance';
import Breadcrumb from '../components/ProductDetailsBreadcrumb';

export const query = graphql`
  query ProductPageTemplate($id: String!) {
    contentfulContentProduct(id: { eq: $id }) {
      ...ContentProductFragment
    }
    contentfulGlobalOptions {
      ...GlobalFragment
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;

const ProductPageTemplate: React.FC<
  PageProps<Queries.ProductPageTemplateQuery, PageContext>
> = (props) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const { data } = props;
  const { contentfulContentProduct } = data;
  const { microcopy, page } = useContext(GlobalStateContext);
  const highlights = contentfulContentProduct.highlights?.raw
    ? renderContentfulRichText(contentfulContentProduct.highlights?.raw)
    : null;
  const description = contentfulContentProduct.description?.raw
    ? renderContentfulRichText(contentfulContentProduct.description?.raw)
    : null;
  const productVideos: IHeroProductVideo[] =
    contentfulContentProduct?.videos?.map((video) => {
      return {
        externalUrl: video?.externalUrl,
        poster: video?.poster?.[0].file.url,
      };
    }) ?? [];

  const productSectionTitles: string[] = [];
  const productSectionRefs: Array<React.RefObject<any>> = [];

  const [productManualsResponse, setProductManualsResponse] = useState(null);

  useEffect(() => {
    const manuals = getProductManuals(contentfulContentProduct?.model);
    manuals
      .then((response) => {
        setProductManualsResponse(response);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setProductManualsResponse(null);
      });
  }, []);

  //Product Family
  const productFamily = contentfulContentProduct?.productFamily?.[0];
  const productFamilyAppearanceTheme: TTheme = productFamily
    ?.productDetailsPrimaryBanner?.appearance?.theme as TTheme;
  const productFamilyAppearancePadding: TSizing = productFamily
    ?.productDetailsPrimaryBanner?.appearance?.padding as TSizing;
  const productFamilyAppearanceMargin: TSizing = productFamily
    ?.productDetailsPrimaryBanner?.appearance?.margin as TSizing;
  const productFamilyAppearanceBgImage: IMediaAsset = productFamily
    ?.productDetailsPrimaryBanner?.appearance?.backgroundImage as IMediaAsset;

  //if an image exist, create the object otherwise null
  const productFamilyProps: ImageCopyCenterProps = productFamily
    ?.productDetailsPrimaryBanner?.content?.primaryImage
    ? {
        ctaBtn:
          productFamily?.productDetailsPrimaryBanner?.content?.secondaryText,
        description: '',
        images: productFamily?.productDetailsPrimaryBanner?.content
          ?.primaryImage as IMediaAsset[],
        link: productFamily?.productDetailsPrimaryBanner?.content
          ?.link?.[0] as IContentLink,
        title: null,
        className: '',
      }
    : null;

  //Product family card for product details page
  const productFamilyCard: ICardGeneric[] = productFamily?.productCardFeature
    ?.primaryImage?.[0]
    ? [
        {
          image: productFamily?.productCardFeature
            ?.primaryImage?.[0] as IMediaAsset,
          children: renderContentfulRichText(
            productFamily?.productCardFeature?.bodyText?.raw,
          ),
          link: productFamily?.productCardFeature?.link?.[0] as IContentLink,
          a11yTitle:
            productFamily?.productCardFeature?.primaryImage?.[0].description,
          legalText: productFamily?.productCardFeature?.secondaryText,
        },
      ]
    : [];

  //Product Line - get first product line
  const productLine =
    contentfulContentProduct?.productLine?.[0]?.productDetailsSecondaryBanner;
  const productLineAppearanceTheme = productLine?.appearance?.theme as TTheme;
  const productLineAppearancePadding = productLine?.appearance
    ?.padding as TSizing;
  const productLineAppearanceMargin = productLine?.appearance
    ?.margin as TSizing;
  const productLineAppearanceBgImage = productLine?.appearance
    ?.backgroundImage as IMediaAsset;

  const productLineProps: ImageCopyCenterProps = {
    ctaBtn: null,
    description: '',
    images: productLine?.content?.primaryImage as IMediaAsset[],
    link: productLine?.content?.link?.[0] as IContentLink,
    title: null,
    className: '',
  };

  //Features
  const featuresPromotionItems: ICarouselSlideContent[] =
    contentfulContentProduct?.features?.map((feature) => {
      return {
        primaryText: feature?.title,
        bodyText: renderContentfulRichText(feature?.bodyText?.raw),
        image: [feature?.featuredImage as IMediaAsset],
      };
    }) ?? [];

  //Get current promotions and ryobi help card
  const productFixedCardFeatures =
    page?.data?.contentfulGlobalOptions.productCardFeatures?.[0]?.contentItems;

  const fixedCards: ICardGeneric[] =
    productFixedCardFeatures?.map((card) => {
      return {
        image: card.primaryImage?.[0].file?.url as IMediaAsset,
        children: renderContentfulRichText(card?.bodyText?.raw),
        link: card?.link?.[0] as IContentLink,
        a11yTitle: '',
        legalText: undefined,
      };
    }) ?? [];

  const warrantyCards: ICardGeneric[] =
    contentfulContentProduct?.warranty?.map((warrantyCardItem) => {
      return {
        image: warrantyCardItem?.warrantyCardLogo as IMediaAsset,
        children: warrantyCardItem?.warrantyDescription,
        link: warrantyCardItem?.warrantyCardLink as IContentLink,
        a11yTitle: warrantyCardItem?.warrantyCardLogo?.description,
        legalText: warrantyCardItem?.warrantyLogoText,
      };
    }) ?? [];

  const cardFeaturesItems: ICardGeneric[] = [
    ...new Set([...warrantyCards, ...productFamilyCard, ...fixedCards]),
  ];

  productSectionTitles.push(
    microcopy?.ProductSectionFeaturesTitle || 'Features',
  );
  productSectionRefs.push(useRef(null));

  //Specifications
  const specifications: any = contentfulContentProduct?.specifications;
  if (contentfulContentProduct.specifications) {
    productSectionTitles.push(
      microcopy?.ProductSectionSpecificationsTitle || 'Specifications',
    );
    productSectionRefs.push(useRef(null));
  }

  const productSpecificationsBodyText = contentfulContentProduct
    .specifications?.[0]?.bodyText
    ? renderContentfulRichText(
        contentfulContentProduct?.specifications?.[0]?.bodyText?.raw,
      )
    : null;

  //Includes
  const kitSpecifications: any = contentfulContentProduct?.kits;
  const productIncludesItems: IProductIncludesCarouselSlideContent[] =
    contentfulContentProduct?.includes?.map((item) => {
      return {
        title: item?.title,
        featuredImage: item?.featuredImage as IMediaAsset,
        featuredImageDescription: item?.featuredImage?.description,
      };
    }) ?? [];

  const productIncludesBodyText = contentfulContentProduct.includes?.[0]
    ?.bodyText
    ? renderContentfulRichText(
        contentfulContentProduct?.includes?.[0]?.bodyText.raw,
      )
    : null;

  if (contentfulContentProduct.includes) {
    productSectionTitles.push(
      microcopy?.ProductSectionIncludesTitle || 'Includes',
    );
    productSectionRefs.push(useRef(null));
  }

  //Support - always present
  productSectionTitles.push(microcopy?.ProductSectionSupportTitle || 'Support');
  productSectionRefs.push(useRef(null));

  const productSupportTabs =
    page?.data?.contentfulGlobalOptions.productSupportTabs?.[0].contentItems;

  const tabItems: ITabItems[] =
    productSupportTabs?.map((tabItem) => {
      return {
        name: tabItem.primaryText,
        bodyText: renderContentfulRichText(tabItem?.bodyText?.raw),
        primaryImage: tabItem?.primaryImage?.[0],
        links: tabItem?.link,
      };
    }) ?? [];

  if (
    tabItems &&
    productManualsResponse &&
    productManualsResponse?.length === 0
  ) {
    const indexOfObject = tabItems.findIndex((object) => {
      return object.name === 'Manuals';
    });

    //remove manuals from tabItems if none are returned from manuals api
    tabItems.splice(indexOfObject, 1);
  }

  const warrantyItems: IProductWarrantyItems[] =
    contentfulContentProduct?.warranty?.map((warrantyItem) => {
      return {
        description: warrantyItem?.warrantyDescription,
        logo: warrantyItem?.warrantyLogo as IMediaAsset,
        logoText: warrantyItem?.warrantyLogoText,
        title: warrantyItem?.warrantyTitle,
        link: warrantyItem?.warrantyCardLink,
      };
    }) ?? [];

  if (tabItems && warrantyItems?.length === 0) {
    const indexOfObject = tabItems.findIndex((object) => {
      return object.name === 'Registration & Warranty';
    });

    //remove registration and warranty if its empty
    tabItems.splice(indexOfObject, 1);
  }

  //Related products
  if (contentfulContentProduct.relatedProducts) {
    productSectionTitles.push(
      microcopy?.ProductSectionRelatedProductsTitle || 'Related Products',
    );
    productSectionRefs.push(useRef(null));
  }

  const productRelatedItems: IProductCarouselSlideContent[] =
    contentfulContentProduct.relatedProducts
      ?.filter(
        (item) =>
          item?.internal?.type === 'ContentfulContentProduct' &&
          !item?.isDiscontinued,
      )
      .map((item) => {
        return {
          name: item?.name,
          model: item?.model,
          featureImage: item?.featureImage as IMediaAsset,
          slug: item?.slug,
          availableFrom: item?.availableFrom,
        };
      }) ?? [];

  const epigraphModels =
    page?.data?.contentfulGlobalOptions.epigraphArModelNumbers;

  const hasEpigraphAr = epigraphModels.includes(
    contentfulContentProduct?.model,
  );

  const sectionsProps: SectionsProps = {
    featuresPromotionItems: featuresPromotionItems,
    cardFeaturesItems: cardFeaturesItems,
    productFamilyProps: productFamilyProps,
    productFamilyAppearancePadding: productFamilyAppearancePadding,
    productFamilyAppearanceMargin: productFamilyAppearanceMargin,
    productFamilyAppearanceTheme: productFamilyAppearanceTheme,
    productFamilyAppearanceBgImage: productFamilyAppearanceBgImage,
    specifications: specifications,
    productIncludesBodyText: productIncludesBodyText,
    productSpecificationsBodyText: productSpecificationsBodyText,
    productIncludesItems: productIncludesItems,
    kitSpecifications: kitSpecifications,
    productLineProps: productLineProps,
    productLineAppearancePadding: productLineAppearancePadding,
    productLineAppearanceMargin: productLineAppearanceMargin,
    productLineAppearanceTheme: productLineAppearanceTheme,
    productLineAppearanceBgImage: productLineAppearanceBgImage,
    tabItems: tabItems,
    productManuals: productManualsResponse,
    tabModel: contentfulContentProduct.model,
    warrantyItems: warrantyItems,
    productRelatedItems: productRelatedItems,
    hasEpigraphAr: hasEpigraphAr,
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const activeSection = useScrollSpy({
    sectionElementRefs: productSectionRefs,
    offsetPx: isMobile ? -86 : -120,
    activeSectionDefault: 9999,
  });

  const handleMobileProductButtonClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleMobileProductNavClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen); //close submenu when clicked
  };

  const featuredProductImage: IMediaAsset[] = [];
  featuredProductImage.push(
    contentfulContentProduct?.featureImage as IMediaAsset,
  );

  const allProductImages: IMediaAsset[] = featuredProductImage?.concat(
    contentfulContentProduct?.secondaryImages as IMediaAsset[],
  );

  return (
    <>
      <Breadcrumb
        prevPath={props.location?.state?.prevPath}
        prevParams={props.location?.state?.prevParams}
        productLine={contentfulContentProduct?.productLine}
        family={contentfulContentProduct?.productFamily}
        category={contentfulContentProduct?.category}
      />
      <div className="container">
        <HeroProduct
          name={contentfulContentProduct?.name}
          model={contentfulContentProduct?.model}
          featureImage={contentfulContentProduct?.featureImage as IMediaAsset}
          highlights={highlights}
          sku={contentfulContentProduct.sku}
          thumbnailImages={allProductImages}
          videos={productVideos}
          isComingSoon={contentfulContentProduct?.isComingSoon}
          isDiscontinued={contentfulContentProduct?.isDiscontinued}
          productFamilies={contentfulContentProduct?.productFamily}
        />
      </div>

      <ProductSubNav
        sectionRefs={productSectionRefs}
        sectionTitles={productSectionTitles}
        isSubMenuOpen={isSubMenuOpen}
        handleMobileProductButtonClick={handleMobileProductButtonClick}
        handleMobileProductNavClick={handleMobileProductNavClick}
        activeSection={activeSection}
      />

      {/* large copy text*/}
      {description && <CopyBanner description={description} />}

      <div className="snap-y pb-lg">
        {productSectionTitles?.map((section, index) => {
          return (
            <React.Fragment key={`scroll-section-${index}`}>
              {section === microcopy?.ProductSectionSupportTitle && (
                <>
                  {productLineProps && (
                    <Appearance
                      padding={productLineAppearancePadding}
                      margin={productLineAppearanceMargin}
                      theme={productLineAppearanceTheme}
                      image={productLineAppearanceBgImage}
                    >
                      <ImageCopyCenter {...productLineProps} />
                    </Appearance>
                  )}
                </>
              )}

              <section
                key={`product-section-${index}`}
                id={section.replace(' ', '-').toLowerCase()}
                className="product-section scroll-mt-[85px] tablet:scroll-mt-[84px] desktop:scroll-mt-[104px]"
                ref={productSectionRefs[index]}
              >
                {getSectionComponentByName(section, sectionsProps)}

                {/* Family banner */}
                {section === microcopy?.ProductSectionFeaturesTitle && (
                  <>
                    {cardFeaturesItems && (
                      <CardsFeature items={cardFeaturesItems} />
                    )}

                    {productFamilyProps && (
                      <Appearance
                        padding={productFamilyAppearancePadding}
                        margin={productFamilyAppearanceMargin}
                        theme={productFamilyAppearanceTheme}
                        image={productFamilyAppearanceBgImage}
                      >
                        <ImageCopyCenter {...productFamilyProps} />
                      </Appearance>
                    )}
                  </>
                )}
              </section>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default ProductPageTemplate;
