import React, { useEffect, useState } from 'react';
import ContentfulLink from './contentful/ContentfulLink';

export interface ProductBreadcrumbProps {
  productLine?: Array<{ title: string; slug: string }>;
  family?: Array<{ title: string; slug: string }>;
  category?: Array<{ name: string; slug: string }>;
  prevPath: string;
  prevParams: string;
}

/**
 * A component to display Breadcrumb on Product details page
 */
const ProductDetailsBreadcrumb: React.FC<ProductBreadcrumbProps> = (props) => {
  const { productLine, family, category, prevParams } = props;

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const breadcrumbsTemp = [
      {
        title: 'RYOBI',
        slug: '/',
      },
    ];
    const paths = props.prevPath?.split('/').filter((v) => v);

    if (paths?.length === 1) {
      // navigation from search page
      if (paths[0] === 'search') {
        breadcrumbsTemp.push({
          title: paths[0],
          slug: `${paths[0]}/${prevParams}`,
        });
      } else {
        // navigation from productLine page - /power-tools, /outdoor or some family pages - /40v, /hp-40v, /hp-18v, /hp-18v-compact
        breadcrumbsTemp.push({
          title: paths[0].split('-').join(' '),
          slug: paths[0],
        });
      }
    } else if (paths?.length === 2) {
      // navigation from some family pages - products/one-plus/, /products/40v/
      const prevPageFamily = family?.find((f) => f.slug === paths[1]);

      if (prevPageFamily) {
        breadcrumbsTemp.push({
          title: prevPageFamily.title,
          slug: paths.join('/'),
        });
      } else {
        // navigation from home page category carousel product list page - /products/{category}
        const prevPageCategory = category?.find((c) => c.slug === paths[1]);

        if (prevPageCategory) {
          breadcrumbsTemp.push({
            title: prevPageCategory.name,
            slug: paths.join('/'),
          });
        }
      }
    } else if (paths?.length > 2) {
      if (paths[0] === 'products') {
        if (paths[1] === 'details') {
          // navigating from product details page Related products
          breadcrumbsTemp.push({
            title: paths[2].split('-').join(' '),
            slug: paths.join('/'),
          });
        } else {
          // navigation from some family pages - /products/one-plus/{category}, /products/40v/{category}
          const prevPageProductFamily = family.find((f) => f.slug === paths[1]);
          const prevPageCategory = category.find((c) => c.slug === paths[2]);

          if (prevPageProductFamily) {
            breadcrumbsTemp.push({
              title: prevPageProductFamily.title,
              slug: `products/${prevPageProductFamily.slug}`,
            });
          }

          if (prevPageProductFamily && prevPageCategory) {
            breadcrumbsTemp.push({
              title: prevPageCategory.name,
              slug: paths.join('/'),
            });
          }
        }
      } else if (paths[1] === 'products') {
        // navigation from product line products list page - /power-tools/products/{category}
        const prevPageProductLine = productLine.find(
          (l) => l.slug === paths[0],
        );
        const prevPageCategory = category.find((c) => c.slug === paths[2]);

        if (prevPageProductLine) {
          breadcrumbsTemp.push({
            title: prevPageProductLine.title,
            slug: prevPageProductLine.slug,
          });
        }

        if (prevPageProductLine && prevPageCategory) {
          breadcrumbsTemp.push({
            title: prevPageCategory.name,
            slug: paths.join('/'),
          });
        }
      }
    }

    setBreadcrumbs(breadcrumbsTemp);
  }, []);

  return (
    <div className="flex gap-x-1 py-4 container text-sm [&_a]:link">
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={item.slug}>
          {index > 0 && <span>{'>'}</span>}
          <ContentfulLink link={item.slug} className="uppercase">
            {item.title}
          </ContentfulLink>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProductDetailsBreadcrumb;
